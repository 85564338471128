import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <PrivateRoute>
          <DashboardLayout />
        </PrivateRoute>
      ),
      children: [
        { path: '', element: <Navigate to="/dashboard/main" replace /> },
        {
          path: 'main',
          element: <DashboardPage />
        },
        { path: 'logout', element: <PageFive /> },
        {
          path: 'blogs',
          children: [
            { path: '', element: <Navigate to="/blogs/all-blogs" replace /> },
            { path: 'all-blogs', element: <BlogsList /> },
            { path: 'add-new-blog', element: <AddNewBlog /> },
            { path: 'all-blogs/:id', element: <AddNewBlog isEdit /> },
            { path: 'all-blogs/delete/:id', element: <DeleteBlog /> }
          ]
        },
        {
          path: 'gallery',
          children: [
            { path: '', element: <Navigate to="/gallery/all-gallery" replace /> },
            { path: 'all-gallery', element: <ImagesList /> },
            { path: 'add-new-gallery', element: <AddNewImage /> },
            { path: 'all-gallery/:id', element: <AddNewImage isEdit /> },
            { path: 'all-gallery/delete/:id', element: <DeleteImage /> }
          ]
        },

        {
          path: 'clients',
          children: [
            { path: '', element: <Navigate to="/clients/all-clients" replace /> },
            { path: 'all-clients', element: <ClientList /> },
            { path: 'add-new-client', element: <AddNewClient /> },
            { path: 'all-clients/:id', element: <AddNewClient isEdit /> },
            { path: 'all-clients/delete/:id', element: <DeleteClient /> }
          ]
        },
        {
          path: 'invoices',
          children: [
            { path: '', element: <Navigate to="/invoices/all-invoices" replace /> },
            { path: 'all-invoices', element: <InvoiceList /> },
            { path: 'add-new-invoice', element: <AddNewInvoice /> },
            { path: 'all-invoices/:id', element: <SingleInvoice /> },
            { path: 'all-invoices/delete/:id', element: <DeleteInvoice /> }
          ]
        },
        {
          path: 'products',
          children: [
            { path: '', element: <Navigate to="/products/all-products" replace /> },
            { path: 'all-products', element: <ProductsList /> },
            { path: 'add-new-product', element: <AddNewProduct /> },
            { path: 'all-products/:id', element: <AddNewProduct isEdit /> },
            { path: 'all-products/delete/:id', element: <DeleteProduct /> }
          ]
        },
        {
          path: 'category',
          children: [
            { path: '', element: <Navigate to="/category/all-blogs-category" replace /> },
            { path: 'all-blogs-category/delete/:id', element: <DeleteCategory /> },
            { path: 'all-blogs-category', element: <CategoryList /> },
            { path: 'all-blogs-category/:id', element: <CategoryList edit /> }
          ]
        },
        {
          path: 'config',
          children: [
            { path: '', element: <Navigate to="/config/complete-config" replace /> },
            { path: 'complete-config', element: <ConfigManagement /> },
            { path: 'complete-config/update/:id', element: <UpdateBusinessConfig edit /> }
          ]
        },
        {
          path: 'product-category',
          children: [
            { path: '', element: <Navigate to="/product-category/all-product-categories" replace /> },
            { path: 'all-product-categories/delete/:id', element: <DeleteProductCategory /> },
            { path: 'all-product-categories', element: <ProductCategoryList /> },
            { path: 'all-product-categories/:id', element: <ProductCategoryList edit /> },
            {
              path: 'all-product-categories/manage-sub-category/:id',
              element: <ProductCategoryList addCategory />
            }
          ]
        },

        {
          path: 'leads',
          children: [
            { path: '', element: <Navigate to="/leads/all-leads" replace /> },
            { path: 'all-leads/delete/:id', element: <DeleteLead /> },
            { path: 'all-leads', element: <LeadsList /> }
          ]
        },
        {
          path: 'tags',
          children: [
            { path: '', element: <Navigate to="/tags/all-tags" replace /> },
            { path: 'all-tags/delete/:id', element: <DeleteTag /> },
            { path: 'all-tags', element: <TagsList /> },
            { path: 'all-tags/:id', element: <TagsList edit /> }
          ]
        },
        {
          path: 'faqs',
          children: [
            { path: '', element: <Navigate to="/faqs/all-faqs" replace /> },
            { path: 'all-faqs/delete/:id', element: <DeleteFaq /> },
            { path: 'all-faqs', element: <FaqsList /> },
            { path: 'all-faqs/:id', element: <FaqsList edit /> }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <LandingPage />
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Dashboard
const DashboardPage = Loadable(lazy(() => import('../pages/DashboardPage')));

// Client Page imports starts here
const AddNewClient = Loadable(lazy(() => import('../pages/clients/AddNewClient')));
const ClientList = Loadable(lazy(() => import('../pages/clients/ClientList')));
const DeleteClient = Loadable(lazy(() => import('../pages/clients/DeleteClient')));

// Invoice Page imports starts here
const AddNewInvoice = Loadable(lazy(() => import('../pages/invoices/AddNewInvoice')));
const SingleInvoice = Loadable(lazy(() => import('../pages/invoices/SingleInvoice')));
const InvoiceList = Loadable(lazy(() => import('../pages/invoices/InvoiceList')));
const DeleteInvoice = Loadable(lazy(() => import('../pages/invoices/DeleteInvoice')));

// Blogs Page imports starts here
const AddNewBlog = Loadable(lazy(() => import('../pages/blogs/AddNewBlog')));
const BlogsList = Loadable(lazy(() => import('../pages/blogs/BlogsList')));
const DeleteBlog = Loadable(lazy(() => import('../pages/blogs/DeleteBlog')));

// Gallery Images Page imports starts here
const AddNewImage = Loadable(lazy(() => import('../pages/imageGallery/AddNewImage')));
const ImagesList = Loadable(lazy(() => import('../pages/imageGallery/ImagesList')));
const DeleteImage = Loadable(lazy(() => import('../pages/imageGallery/DeleteImage')));

// Business Config Routes
const ConfigManagement = Loadable(lazy(() => import('../pages/config/ConfigManagement')));
const UpdateBusinessConfig = Loadable(lazy(() => import('../pages/config/UpdateBusinessConfig')));

// Product Page imports starts here
const AddNewProduct = Loadable(lazy(() => import('../pages/products/AddNewProduct')));
const ProductsList = Loadable(lazy(() => import('../pages/products/ProductsList')));
const DeleteProduct = Loadable(lazy(() => import('../pages/products/DeleteProduct')));

// Product Category Page imports starts here
const ProductCategoryList = Loadable(lazy(() => import('../pages/productCategory/ProductCategoryList')));
const DeleteProductCategory = Loadable(lazy(() => import('../pages/productCategory/DeleteProductCategory')));

// Leads Page imports starts here
const LeadsList = Loadable(lazy(() => import('../pages/leads/LeadsList')));
const DeleteLead = Loadable(lazy(() => import('../pages/leads/DeleteLead')));

// Category Page imports starts here
const CategoryList = Loadable(lazy(() => import('../pages/category/CategoryList')));
const DeleteCategory = Loadable(lazy(() => import('../pages/category/DeleteCategory')));

// FAQ's Page imports starts here
const FaqsList = Loadable(lazy(() => import('../pages/faqs/FaqsList')));
const DeleteFaq = Loadable(lazy(() => import('../pages/faqs/DeleteFaq')));

// Tags Page imports starts here
const TagsList = Loadable(lazy(() => import('../pages/tags/TagsList')));
const DeleteTag = Loadable(lazy(() => import('../pages/tags/DeleteTag')));

const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
