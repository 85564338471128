import React, { useEffect } from 'react';
import decode from 'jwt-decode';
import { PropTypes } from 'prop-types';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setAuthorizationToken } from '../utils/setAuthorizationToken';
import { setIsAuthenticated } from '../features/appSlice';
import { getUserRequest, refreshTokenRequest } from '../features/userSlice';

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.app.isAuthenticated);

  const token = localStorage.getItem('dbToken');
  const refToken = localStorage.getItem('dbRefToken');

  const refreshToken = async () => {
    try {
      if (!refToken) {
        localStorage.removeItem('dbToken');
        setIsAuthenticated(false);
        navigate('/');
        return;
      }

      dispatch(refreshTokenRequest({ refToken: JSON.parse(refToken) }));
    } catch (error) {
      localStorage.removeItem('dbRefToken');
      localStorage.removeItem('dbToken');
      setIsAuthenticated(false);
      setAuthorizationToken(false);
      navigate('/');
    }
  };

  // before

  useEffect(() => {
    if (!token) {
      setAuthorizationToken(false);
      dispatch(setIsAuthenticated(false));
      navigate('/');
      return;
    }
    if (token) {
      const { expiresIn } = decode(JSON.parse(token));

      if (expiresIn > Date.now()) {
        dispatch(setIsAuthenticated(true));
        setAuthorizationToken(JSON.parse(token));
        dispatch(getUserRequest());
      } else {
        refreshToken();
      }
    }
    // eslint-disable-next-line
  }, [token, dispatch, navigate]);

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to={{ from: location.state?.from }} state={{ from: location.state?.from }} replace />
  );
};
PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired
};
export default PrivateRoute;
