import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { productAPI } from '../api/productAPI';
import { appLoading, setMessage } from './appSlice';

// async thunk request to Register new Product category
export const addNewProductRequest = // eslint-disable-next-line
  createAsyncThunk('products/addNewProductRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const reg = await productAPI.add(dataToSend);
      dispatch(setMessage(reg.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));

        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

// async thunk request to get All product category
export const getAllProductsRequest = createAsyncThunk(
  'products/getAllProductsRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await productAPI.all();
      dispatch(setProductsCount(data.result));
      dispatch(setAllProducts(data.data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single request
export const getSingleProductRequest = createAsyncThunk(
  'products/getSingleProductRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await productAPI.single(dt);
      dispatch(setSingleProduct(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to updateSingleProductRequest
export const updateSingleProductRequest = createAsyncThunk(
  'products/updateSingleProductRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await productAPI.update(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));

        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deleteSingleProductRequest
export const deleteSingleProductRequest = createAsyncThunk(
  'products/deleteSingleProductRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await productAPI.delete(dt);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  totalProducts: 0,
  allProducts: [],
  singleProduct: {},
  errorInProduct: ''
};

export const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setAllProducts: (state, action) => {
      state.allProducts = action.payload;
    },
    setProductsCount: (state, action) => {
      state.totalProducts = action.payload;
    },
    setSingleProduct: (state, action) => {
      state.singleProduct = action.payload;
    },
    clearHasError: (state) => {
      state.errorInProduct = '';
    }
  },
  extraReducers: {
    [addNewProductRequest.rejected]: (state, action) => {
      state.errorInProduct = action.payload;
    },
    [getAllProductsRequest.rejected]: (state, action) => {
      state.errorInProduct = action.payload;
    },
    [getSingleProductRequest.rejected]: (state, action) => {
      state.errorInProduct = action.payload;
    },
    [updateSingleProductRequest.rejected]: (state, action) => {
      state.errorInProduct = action.payload;
    },
    [deleteSingleProductRequest.rejected]: (state, action) => {
      state.errorInProduct = action.payload;
    }
  }
});
export const { setAllProducts, setSingleProduct, setProductsCount, clearHasError } = productSlice.actions;

export default productSlice.reducer;
