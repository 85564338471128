import axios from 'axios';

const baseURL = `${process.env.REACT_APP_API_URL}`;

export const galleryImageAPI = {
  add: async (info) => axios.post(`${baseURL}/images`, info).then((res) => res.data),
  all: async () => axios.get(`${baseURL}/images`).then((res) => res.data),
  single: async (idToSearch) => axios.get(`${baseURL}/image/${idToSearch}`).then((res) => res.data),
  update: async (idToSearch) => {
    const { id, ...details } = idToSearch;
    return axios.put(`${baseURL}/image/${id}`, details).then((res) => res.data);
  },

  delete: async (idToSearch) => axios.delete(`${baseURL}/image/${idToSearch}`).then((res) => res.data)
};
