import { configureStore } from '@reduxjs/toolkit';
import appReducer from '../features/appSlice';
import userReducer from '../features/userSlice';
import blogReducer from '../features/blogSlice';
import categoryReducer from '../features/categorySlice';
import tagReducer from '../features/tagSlice';
import productReducer from '../features/productSlice';
import productSubCategoryReducer from '../features/productSubCategorySlice';
import leadReducer from '../features/leadSlice';
import faqReducer from '../features/faqSlice';
import invoiceReducer from '../features/invoiceSlice';
import clientReducer from '../features/clientSlice';
import notificationReducer from '../features/notificationSlice';
import productCategoryReducer from '../features/productCategorySlice';
import galleryImageReducer from '../features/galleryImageSlice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    blogs: blogReducer,
    tags: tagReducer,
    categories: categoryReducer,
    productCategories: productCategoryReducer,
    productSubCategories: productSubCategoryReducer,
    products: productReducer,
    leads: leadReducer,
    faqs: faqReducer,
    notifications: notificationReducer,
    invoices: invoiceReducer,
    clients: clientReducer,
    galleryImages: galleryImageReducer
  }
});
