import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { galleryImageAPI } from '../api/galleryImageAPI';
import { appLoading, setMessage } from './appSlice';

// async thunk request to Add New Image to gallery
export const addNewImageToGalleryRequest = // eslint-disable-next-line
  createAsyncThunk('galleryImages/addNewImageToGalleryRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const reg = await galleryImageAPI.add(dataToSend);
      dispatch(setMessage(reg.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

// async thunk request to get All gallery Images
export const getAllGalleryImagesRequest = createAsyncThunk(
  'galleryImages/getAllGalleryImagesRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await galleryImageAPI.all();
      dispatch(setAllImages(data));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single Image
export const getSingleGalleryImageRequest = createAsyncThunk(
  'galleryImages/getSingleGalleryImageRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await galleryImageAPI.single(dt);
      dispatch(setSingleGalleryImage(data));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to updateImageCaptionRequest
export const updateImageCaptionRequest = createAsyncThunk(
  'galleryImages/updateImageCaptionRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await galleryImageAPI.update(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deleteGalleryImageRequest
export const deleteGalleryImageRequest = createAsyncThunk(
  'galleryImages/deleteGalleryImageRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await galleryImageAPI.delete(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allImages: [],
  singleImage: {},
  errorInGalleryImage: ''
};

export const galleryImageSlice = createSlice({
  name: 'galleryImages',
  initialState,
  reducers: {
    setAllImages: (state, action) => {
      state.allImages = action.payload;
    },
    setSingleGalleryImage: (state, action) => {
      state.singleImage = action.payload;
    },
    clearHasError: (state) => {
      state.errorInGalleryImage = '';
    }
  },
  extraReducers: {
    [addNewImageToGalleryRequest.rejected]: (state, action) => {
      state.errorInGalleryImage = action.payload;
    },
    [getAllGalleryImagesRequest.rejected]: (state, action) => {
      state.errorInGalleryImage = action.payload;
    },
    [getSingleGalleryImageRequest.rejected]: (state, action) => {
      state.errorInGalleryImage = action.payload;
    },
    [updateImageCaptionRequest.rejected]: (state, action) => {
      state.errorInGalleryImage = action.payload;
    },
    [deleteGalleryImageRequest.rejected]: (state, action) => {
      state.errorInGalleryImage = action.payload;
    }
  }
});
export const { setAllImages, setSingleGalleryImage, clearHasError } = galleryImageSlice.actions;

export default galleryImageSlice.reducer;
